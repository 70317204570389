<template>
  <div>
    <Navigation />

  <div class="container" style="margin-top: 100px;">
    <v-card flat title="Students">
      <v-text-field v-model="recherche" label="Search" prepend-inner-icon="mdi-magnify" single-line variant="outlined"
        hide-details></v-text-field>

      <v-data-table dense class="bg-transparent" :items-per-page="10" :headers="headers" :items="people"
        :search="recherche">
        <template v-slot:item.image="{ item }">
          <div class="py-2">
            <v-avatar size="40">
              <v-img :src="item.image" :alt="'Image de ' + item.fullname"></v-img>
            </v-avatar>
          </div>
        </template>
        <template v-slot:item.setting="{ item }">
          <span class="d-none">{{ item.setting }}</span>
          <v-btn small color="orange accent-3" class="white--text" @click="openPeople(item)">Open</v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
  </div>
</template>
<script>


import db from "@/db.js";

  export default {
    data() {
      return {
        recherche: '',
        headers: [
          {
            text: 'Image',
            value: 'image'
          },
          {
            text: 'Name',
            value: 'fullname'
          },
          {
            text: 'City',
            value: 'where_do_you_live'
          },
          {
            text: 'Settings',
            value: 'setting'
          },
        ],
        people: [],
      }
    },
    mounted(){
      if(!this.$store.state.ngayi.email) {
        this.$router.push("/login");
      }
      this.getStudents();
    },
    components:{
        Navigation: () => import("../Includes/Nav.vue"),
    },
    methods:{
      openPeople(person){
        this.$store.state.StudentProfile = person;
        this.$router.push("/student_profile");
      },
      getStudents(){
      var that = this;

      db.collection("users")
      .orderBy("fullname", "asc")
      .onSnapshot(snapshot => {

        that.people = [];

        snapshot.forEach(doc => {
          
          that.people.push({
            id: doc.id,
            address: doc.data().address,
            ceremony: doc.data().ceremony,
            createdAt: doc.data().createdAt,
            email: doc.data().email,
            favorite_class: doc.data().favorite_class,
            fullname: doc.data().fullname,
            graduation_year: doc.data().graduation_year,
            in_high_school: doc.data().in_high_school,
            image: doc.data().image,
            level: doc.data().level,
            parent_email: doc.data().parent_email,
            parent_fullname: doc.data().parent_fullname,
            parent_phone: doc.data().parent_phone,
            phone: doc.data().phone,
            study_after_high_school: doc.data().study_after_high_school,
            when_grow_up: doc.data().when_grow_up,
            where_do_you_live: doc.data().where_do_you_live,
            why_scholarship: doc.data().why_scholarship,
            year_currently_in: doc.data().year_currently_in
          });
        });

      });

    }
    }
  }
</script>